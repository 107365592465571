<template>
  <!--
    An error page to land when getting a 404 error
  -->
  <div class="error404">
    <Portlet
      :title="$t('errorComponent.backendEndpointNotAvailable')"
      sub-title="404"
      icon="bug"
    >
      <p>{{ $t('errorComponent.pleaseReportToDevelopmentTeam') }}</p>
    </Portlet>
  </div>
</template>

<script>
export default {
  name: "Error404"
}
</script>
